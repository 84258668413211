<template>
  <div>
    <nav-bar>
      <div class="edition-center">
        <div class="page-container" v-if="orderDetail.id">
          <div class="title-bar">Información del pedido</div>
          <div class="order-bar">
            <img src="@/assets/order/line.png" alt="" />
            <div class="order-info">
              <div class="order-info-left">
                <div style="margin-bottom: 20px">
                  <div class="title">la dirección de cosecha:</div>
                  <div>
                    <p>
                      {{
                        orderDetail.orderDetail &&
                        orderDetail.orderDetail.shipUsername
                      }}
                      {{
                        orderDetail.orderDetail &&
                        orderDetail.orderDetail.shipMobile
                      }}
                    </p>
                    <p style="color: #979797">
                      {{
                        orderDetail.orderDetail &&
                        orderDetail.orderDetail.shipAddress
                      }}
                    </p>
                  </div>
                </div>
                <div>
                  <div class="title">Observaciones del pedido:</div>
                  <div>
                    <p>{{ orderDetail.additionalContent }}</p>
                  </div>
                </div>
              </div>
              <div class="order-info-right">
                <div>
                  <div>El importe del reembolso：</div>
                  <div>
                    {{
                      (orderDetail.orderGoodsDetail.buyPriceOriginal -
                        orderDetail.orderGoodsDetail.buyPriceExchange)
                        | filterPrice
                    }}
                  </div>
                </div>
                <div>
                  <div>La razón del reembolso：</div>
                  <div>{{ orderDetail.reasonContent }}</div>
                </div>
                <div>
                  <div>El tiempo de la solicitud：</div>
                  <div>{{ orderDetail.applyTime }}</div>
                </div>
                <!-- 客服电话 -->
                <div class="">
                  <div>Línea directa al consumidor：</div>
                  <div>{{ orderDetail.orderDetail.customerServiceDetail.employeePhone }}</div>
                </div>
              </div>
            </div>

            <img src="@/assets/order/line.png" alt="" />
          </div>

          <!-- 订单表格 ----------------------------------- -->
          <div class="order-table">
            <div class="header">
              <div style="margin-right: 20px">Productos Básicos</div>
              <div>Precio unitario</div>
              <div>Cantidad</div>
              <div>Importe de la restitución</div>
            </div>
            <div class="order-list">
              <div class="order-item">
                <div class="item-header">
                  <div>números 345456566767676</div>
                  <div>
                    <div
                      style="
                        color: #8acf66;
                        display: inline-block;
                        margin-right: 15px;
                      "
                    >
                      {{ statusMap[orderDetail.orderStatus] }}
                    </div>
                    <span>
                      {{
                        orderDetail.createdTime &&
                        orderDetail.createdTime.split(' ')[0]
                      }}
                    </span>
                  </div>
                </div>
                <div class="item-content" v-for="(child,childIndex) in orderDetail.orderGoodObjList" :key="childIndex">
                  <div class="item-goods">
                    <div class="item-goods-list">
                      <div class="item-goods-flex">
                        <div style="flex: none">
                          <div class="list-item">
                            <div class="item-img">
                              <img
                                :src="
                                  child.goodsCoverUrl
                                    | filterPicture
                                "
                                alt=""
                              />
                            </div>
                            <div class="item-text">
                              <div class="item-name">
                                <span class="goods-sn">
                                  {{ child.goodsSn }}
                                </span>
                                <span>
                                  {{ child.goodsName }}
                                </span>
                              </div>
                              <div class="specs">
                                <span
                                  v-for="(item, index) in JSON.parse(child.goodsSpecs)"
                                  :key="index"
                                >
                                  {{ (index === 0 ? '' : ' / ') + item.name }} :
                                  {{ item.value }}
                                </span>
                              </div>
                              <div class="barCode">
                                Código de barras：{{
                                  child.skuBarCode
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="price">
                          COP
                          {{
                            child.goodsPrice
                              | filterPrice
                          }}
                        </div>
                        <div>{{child.buyNums}}</div>
                        <div>
                          {{ child.deliveryNumber }}
                        </div>
                        <div class="price">
                          COP
                          {{
                            child.sumOriginPrice
                              | filterPrice
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav-bar>
  </div>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
export default {
  components: {
    navBar
  },

  data() {
    return {
      orderDetail: [],
      certificateType: {
        RECEIPT: 'Recibos',
        INVOICE: 'Facturas (19% IVA)'
      },
      settlementType: [
        'Pago inmediato',
        'Pago contra entrega',
        'Pago con crédito'
      ],
      statusMap: {
        UNPAY: 'Pedido listo',
        PAY: 'Envío pendiente',
        DELIVERY: 'Pendiente por recibir',
        RECEIVE: 'Pendiente por comentarios',
        COMMENT: 'Completados',
        CANCEL: 'Pedido cerrado',
        CLOSE: 'Pedido cerrado',
        REVIEW: 'Revisión pendiete',
        CONFIRM: 'Confirmación  pendiente'
      },
      orderAfterStatus: ''
    }
  },

  created() {
    this.getOrderDetail()
  },

  methods: {
    getOrderDetail() {
      this.$axios
        .post('/orderAftersale/detail/' + this.$route.params.id)
        .then((res) => {
          this.orderDetail = res.data.result
          console.log('订单详情', this.orderDetail)

          if (this.orderDetail.additionalImageUrl) {
            this.orderDetail.additionalImageUrl = JSON.parse(
              this.orderDetail.additionalImageUrl
            )
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  display: block;
}

.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.page-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .order-bar {
    margin-top: 20px;
    img {
      width: 100%;
    }
    .order-info {
      display: flex;
      .order-info-left {
        width: 360px;
        padding: 20px;
        background: #f9fbf9;
        border-right: 1px solid #e7e7e7;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
        }
        p {
          font-size: 18px;
        }
      }
      .order-info-right {
        width: 600px;
        padding: 20px 40px;
        color: #979797;
        font-size: 18px;
        > div {
          display: flex;
          justify-content: space-between;
          padding: 3px 0;
          color: #606060;
        }
      }
    }
  }

  .add {
    width: 120px;
    margin: 20px 0;
    img {
      width: 100%;
    }
  }

  .order-table {
    margin-top: 40px;
  }

  .price-container {
    padding: 40px 0;
    color: #282828;
    font-size: 20px;
    text-align: right;
    p {
      padding: 5px 0;
    }
    .value {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss" scoped>
$goods-item-height: 118px;

.price {
  color: #d43a57;
}

.header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #979797;
  padding: 10px 0 10px 20px;
  background: #f9fbf9;
  > div {
    flex: 1;
    text-align: center;
  }
}

.order-list {
  .order-item {
    margin-top: 20px;
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #f7fff7;
      font-size: 14px;
      color: #606060;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
    }
    .item-content {
      display: flex;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .item-goods {
        flex: 6.5;
        .item-goods-list {
          // padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          .item-goods-flex {
            display: flex;
            align-items: center;
            height: $goods-item-height;
            > div {
              flex: 1;
            }
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
      > div {
        flex: 1;
        border-left: 1px solid #e7e7e7;
        padding-top: 10px;
        .alignment {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep .el-button {
            white-space: normal;
          }
        }
        .order-status {
          height: $goods-item-height;
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          ::v-deep .el-button {
            white-space: normal;
          }
          ::v-deep .el-button + .el-button {
            margin: 0;
          }
        }
        .order-status:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.list-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  text-align: initial;
  .item-img {
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
    }
  }
  .item-text {
    max-width: 250px;
    position: relative;
    .item-name {
      font-size: 14px;
      color: #000;
      margin: 8px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      .goods-sn {
        display: inline-block;
        background: #fcbbbb;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
    .specs,
    .barCode {
      color: #979797;
      font-size: 14px;
      margin: 2px 0;
    }
  }
}
</style>
